import { lazy } from "react";
import { getUserToken } from "../api/wrapper.js";

const isLoggedIn = getUserToken();

const PublicRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/landing/index.js")),
    exact: true,
    show: true,
  },
  {
    path: "pricing",
    component: lazy(() => import("../pages/pricing/index.js")),
    exact: true,
    show: isLoggedIn ? false : true,
  },
  {
    path: "extension",
    component: lazy(() => import("../pages/extension/index.js")),
    exact: true,
    show: true,
  },
  // {
  //   path: "blog",
  //   component: lazy(() => import("../pages/blog/index.js")),
  //   exact: true,
  //   show: true,
  // },
  // {
  //   path: "blog/*",
  //   component: lazy(() => import("../pages/blog-details/index.js")),
  //   exact: true,
  //   show: true,
  // },
  {
    path: "contact-us",
    component: lazy(() => import("../pages/contact-us/index.js")),
    exact: true,
    show: true,
  },
  {
    path: "*",
    component: lazy(() => import("../pages/landing/index.js")),
    exact: true,
    show: true,
  },
  {
    path: "/login",
    exact: true,
    component: lazy(() => import("../pages/LoginFlow/login/index.js")),
    show: isLoggedIn ? false : true,
  },

  // {
  //   path: "/history",
  //   exact: true,
  //   component: lazy(() => import("../pages/history/index.js")),
  //   show: true,
  // },
  // {
  //   path: "/terms-conditions",
  //   exact: true,
  //   component: lazy(() => import("../pages/terms-conditions/index.js")),
  //   show: true,
  // },
  {
    path: "/privacy-policy",
    exact: true,
    component: lazy(() => import("../pages/privacy-policy/index.js")),
    show: true,
  },

  {
    path: "/redemption/*",
    exact: true,
    component: lazy(() => import("../pages/redemption/index.js")),
    show: true,
  },
];

const PrivateRoutes = [
  {
    path: "/history",
    exact: true,
    component: lazy(() => import("../pages/history/index.js")),
  },
  {
    path: "/profile",
    exact: true,
    component: lazy(() => import("../pages/profile/index.js")),
  },
  {
    path: "/subscription",
    exact: true,
    component: lazy(() => import("../pages/subscription/index.js")),
  },
  {
    path: "/redemption/*",
    exact: true,
    component: lazy(() => import("../pages/redemption/index.js")),
    show: true,
  },
];

export { PublicRoutes, PrivateRoutes };
