import axios from "axios";
import Cookies from "js-cookie";

export const getUserToken = () => {
  const token = Cookies.get("idToken");
  return token;
};

export const wrapperAPI = async ({
  method,
  path,
  data = undefined,
  params = {},
  isTokenRequired = true,
}) => {
  const token = getUserToken();
  let headers = { "Content-Type": "application/json" };
  if (isTokenRequired) {
    headers = { Authorization: `Bearer ${token}` };
  }
  const request = {
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method,
    headers,
    params,
    data,
  };

  try {
    const res = await axios(request);
    return res;
  } catch (error) {
    throw error;
  }
};
