import React, { Suspense } from "react";
import { PublicRoutes, PrivateRoutes } from "./routes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { RotatingLines } from "react-loader-spinner";
import { getUserToken } from "./api/wrapper";

function App() {
  const isLoggedIn = getUserToken();

  const Loader = () => (
    <div className="flex justify-center items-center h-screen">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="32"
        visible={true}
      />
    </div>
  );
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {PublicRoutes.map(({ path, exact, component: Component, show }) => {
            return show ? (
              <Route
                key={path}
                path={path}
                exact={exact}
                element={<Component></Component>}
              />
            ) : (
              <Route
                key={path}
                path={path}
                exact={exact}
                element={<Navigate to="/" />}
              />
            );
          })}
          {PrivateRoutes.map(({ path, exact, component: Component, show }) => {
            return isLoggedIn || show ? (
              <>
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  element={<Component></Component>}
                />
              </>
            ) : (
              <Route
                key={path}
                path={path}
                exact={exact}
                element={<Navigate to="/" />}
              />
            );
          })}
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
